import { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import libro from '../../../../images/libro.png'
import DetalleRemito from '../../DetalleRemito/DetalleRemito';
import agregarImagen from '../../../../images/agregarImagen.png'
import eliminarImagen from '../../../../images/eliminarImagen.png'
import foto from '../../../../images/foto.png'
import CargarArchivo from './CargarArchivo';
import EliminarArchivo from './EliminarArchivo';



export default function BodyRemitos({ remitos, handleArchivoCargado, handleArchivoEliminado }) {

  const sucursal = localStorage.getItem('sucursal');
  const admin = localStorage.getItem('admin')

  const [detalleMovStock, setDetalleMovStock] = useState([]);
  const [showDetalle, setShowDetalle] = useState(false);
  // Aca alamceno los datos del registro que hago click
  const [selectedRem, setSelectedRem] = useState('');
  // estado para abrir el formulario para cargar el pdf/imagen
  const [isModalOpen, setIsModalOpen] = useState(false);
  // estado para el modal de eliminar
  const [isModalEliminarOpen, setIsModalEliminarOpen] = useState(false);
  // estado para la clave del remito al cual le voy a agregar un pdf/imagen
  const [selectedClave, setSelectedClave] = useState(null);
  // estado para almacenar el id del archivo que se va a eliminar
  const [idArchivo, setIdArchivo] = useState(null);

  const handleOpenModal = (clave) => {
    setSelectedClave(clave); // Guarda la clave seleccionada
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClave(null); // Limpia la clave seleccionada
  };

  const handleOpenModalEliminar = (id) => {
    setIdArchivo(id); // Guarda el id del archivo seleccionado
    setIsModalEliminarOpen(true);
  };

  const handleCloseModalEliminar = () => {
    setIdArchivo(null); // Limpia el id del archivo seleccionado
    setIsModalEliminarOpen(false);
  };

  const handleVisualizarDetalle = async (Detalles, rem) => {
    setSelectedRem(rem)
    setDetalleMovStock(Detalles)
    setShowDetalle(true);
  };

  const closeModal = () => {
    setShowDetalle(false);
    setDetalleMovStock('');
  };


  const handleAbrirArchivo = (base64Archivo) => {
    if (!base64Archivo) return;

    // Verificar si el archivo es un PDF
    if (base64Archivo.includes('data:application/pdf')) {
      const byteCharacters = atob(base64Archivo.split(',')[1]); // Decodificar base64
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      const blob = new Blob(byteArrays, { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank'); // Abrir el PDF en una nueva ventana
    }
    // Verificar si el archivo es una imagen
    else if (base64Archivo.includes('data:image')) {
      const byteCharacters = atob(base64Archivo.split(',')[1]); // Decodificar base64
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }
      const blob = new Blob(byteArrays, { type: 'image/png' }); // Asegúrate de que el tipo coincida con el formato de la imagen
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank'); // Abrir la imagen en una nueva ventana
    } else {
      alert('El archivo no es un PDF ni una imagen válida.');
    }
  };


  const handleAbrirArchivoDesdeAPI = async (clave) => {
    if (!clave) {
      toast.warn("No hay clave de archivo disponible.", { autoClose: 3000 });
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/archivoremito/${sucursal}/${clave}`);

      if (!response.data || !response.data?.archivo) {
        toast.error("EL REMITO NO CONTIENE IMAGEN ADJUNTA.", { autoClose: 3000 });
        return;
      }

      handleAbrirArchivo(response.data?.archivo);
    } catch (error) {
      console.error("Error al obtener el archivo:", error);

      if (error.response) {
        // Mensaje específico del backend
        toast.error(error.response.data.mensaje, { position: "top-center", autoClose: 3000 });
      } else {
        toast.error("Error al abrir el archivo.", { position: "top-center", autoClose: 3000 });
      }
    }
  };



  return (
    <tbody className="" >
      {remitos?.map((rem, index) => (

        <tr key={index} className="bg-opacity-70 bg-white text-center text-sm font-semibold hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
          <td className="p-0.5 border">{rem?.Fecha}</td>
          <td className="p-0.5 border" >{rem?.Codadminis}</td>
          <td className="p-0.5 border text-right pr-1" >{rem?.Talonario}-{rem?.Nrocomprobante}</td>
          <td className="p-0.5 border">{rem?.Tipomov}</td>
          <td className="p-0.5 border">{rem?.Deposito}</td>
          <td
            className="p-0.5 border cursor-pointer"
            title={rem?.Comentarios}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
            }}
          >
            {rem?.Comentarios}
          </td>
          <td className={`p-0.5 border ${rem?.Detalles[0]?.Gfaidfactura == '0' && rem?.Genfactura != '0' ? 'text-blue-600' : rem?.Genfactura == '0' ? 'text-red-600' : 'text-green-600'}`}>
            {
              rem?.Detalles[0]?.Gfaidfactura == '0' && rem?.Genfactura != '0' ?
                'PENDIENTE DE FACTURA' :
                rem?.Detalles[0]?.Talonario && rem?.Detalles[0]?.Nrocomprobante ?
                  'FAC N°: ' + rem?.Detalles[0]?.Talonario + '-' + rem?.Detalles[0]?.Nrocomprobante :
                  rem?.Genfactura == '0' ? 'NO GENERA FACTURA' : 'FALTA TALONARIO Y COMPROBANTE'
            }
          </td>
          <td className="p-0.5 border" >
            {rem?.Propiedad == '1' ? 'PROPIO' : rem?.Propiedad == '2' ? 'VTA.DIRECTA' : rem?.Propiedad == '3' ? 'SIEMBRA' : rem?.Propiedad == '4' ? 'CTA. Y ORDEN' : rem?.Propiedad == '5' ? 'PRESTAMOS' : rem?.Propiedad == '6' ? 'CONSIGNADOS' : ''}
          </td>
          <td className="p-0.5 border">{rem?.Vtaanticipada == '1' ? '✔' : ''}</td>
          <td className="p-0.5 border">{rem?.Muevestock == '1' ? '✔' : ''}</td>
          <td className="p-0.5 border" >{rem?.Genfactura == '1' ? '✔' : ''}</td>
          <td title="Visualizar Detalle" className="p-0.5 border" >
            <button onClick={() => handleVisualizarDetalle(rem?.Detalles, rem)}>
              <img src={libro} alt="libro" className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer" />
            </button>
          </td>

          <td title="Abrir archivo" className="p-0.5 border">
            {rem?.Archivo && ( // Solo se muestra si Archivo es true
              <button
                onClick={() => handleAbrirArchivoDesdeAPI(rem?.Clave)}
                className="cursor-pointer"
              >
                <img
                  src={foto} // Ícono de foto
                  alt="Abrir archivo"
                  className="lg:w-7 w-7 h-7 lg:h-6"
                />
              </button>
            )}
          </td>


          {
            admin === 'true' && (
              <td title={rem?.Archivo ? "Eliminar Archivo" : "Agregar Archivo"} className="p-0.5 border">
                {rem?.Archivo ? (
                  // Botón para eliminar archivo
                  <button
                    onClick={() => handleOpenModalEliminar(rem?.idArchivo)} // Llama a la función para eliminar archivo
                    className="opacity-100"
                  >
                    <img
                      src={eliminarImagen}
                      alt="Eliminar Imagen"
                      className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer"
                    />
                  </button>
                ) : (
                  // Botón para agregar archivo
                  <button
                    onClick={() => handleOpenModal(rem?.Clave)}
                    className="opacity-100"
                  >
                    <img
                      src={agregarImagen}
                      alt="Agregar Imagen"
                      className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer"
                    />
                  </button>
                )}
              </td>
            )
          }




        </tr>

      ))
      }

      {showDetalle && <DetalleRemito detalleMovStock={detalleMovStock} onClose={closeModal} remito={selectedRem} />}

      {isModalOpen && <CargarArchivo clave={selectedClave} handleCloseModal={handleCloseModal} handleArchivoCargado={handleArchivoCargado} />}

      {isModalEliminarOpen && <EliminarArchivo idArchivo={idArchivo} handleCloseModalEliminar={handleCloseModalEliminar} handleArchivoEliminado={handleArchivoEliminado} />}
    </tbody >
  )
}
